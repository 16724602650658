<template>
  <!--
    Used to execute device-commands.
  -->
  <div class="commandDialogMaintenance">
    <div class="row">
      <div class="col-12 col-sm-6">
        <table class="defaultTable">
          <thead>
            <tr>
              <th>{{ $t('deviceCommandDialogExecuteComponent.command') }}</th>
              <th>{{ $t('deviceCommandDialogExecuteComponent.options') }}</th>
              <th class="d-none d-lg-table-cell">
                {{ $t('deviceCommandDialogExecuteComponent.executed') }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(command, index) in commands"
              :key="`row${ index }`"
            >
              <td>{{ command.name }}</td>
              <td>
                <button
                  class="btn btn-primary"
                  @click="executeCommand(command)"
                >
                  <font-awesome-icon
                    class="mr-2"
                    icon="terminal"
                  />{{ $t('execute') }}
                </button>
              </td>
              <td class="d-none d-lg-table-cell">
                {{ command.lastRequest ? dateTime_dateTimeSeconds(command.lastRequest) : '' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12 col-sm-6">
        <LoadingPlaceholder v-if="executing" />
        <pre
          v-else-if="response"
          class="border p-4 pre"
        >{{ response }}</pre>
        <template v-else>
          {{ $t('noDataAvailable') }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: "CommandDialoMaintenance",
  mixins: [
    dateTimeMixin
  ],
  props: {
    commands: {
      type: Array,
      required: true
    },
    deviceId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      response: null,
      executing: false
    }
  },
  methods: {
    executeCommand (command) {
      this.executing = true;
      command.lastRequest = Date.now();
      this.$nextTick(() => {
        window.$('[data-toggle="tooltip"]').tooltip();
      });
      this.response = null;
      let commandRequest = {
        'Command': command.name,
        'Args': ''
      };
      this.axios.post(`/CentralDeviceManagement/ExecuteCommand?deviceUuid=${ this.deviceId }`, commandRequest)
        .then((response) => {
          if (response.data && response.data.output) {
            this.response = response.data.output;
          }
        })
        .finally(() => {
          this.executing = false;
        });
    }
  }
}
</script>

<style scoped>
.pre {
  height: 200px;
  overflow: scroll;
  white-space: pre;
}

</style>
